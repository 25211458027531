.app__social {
  display: flex;
  justify-content: center;  /* Centriranje ikona unutar vertikalnog okvira */
  align-items: center;
  flex-direction: column;   /* Poredaj ikone vertikalno */
  padding: 1rem;
  position: fixed;          /* Drži ikone uvijek na ekranu */
  top: 50%;                 /* Postavljanje na sredinu ekrana po visini */
  left: 1rem;               /* Udaljenost od lijevog ruba ekrana */
  z-index: 1000;            /* Osiguraj da je iznad drugih elemenata */
  transform: translateY(-50%); /* Centriranje ikona vertikalno */
}

.social__links {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: gray;
  margin: 0.25rem;
  border: 1px solid gray;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.social__links:hover {
  background-color: #005aaa;  /* Plava boja na hover */
  border-color: #005aaa;
}

.social__links svg {
  color: white; /* Ikone imaju bijelu boju */
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 765px) {
  .app__social {
    display: none;  /* Skriva društvene ikone na manjim ekranima */
  }

  .copyright {
    padding: 2rem;
  }
}
