/* Stil za uvodni tekst sa bijelim okvirom */
.Poruka {
  background-color: white; /* Bijela pozadina */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Stil za Slider */
.Slider {
  position: relative;
  width: 100%;
  height: 300px; /* Visina slidera, možete prilagoditi */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
}

/* Stil za slike unutar Slider-a */
.Slider__images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Skriva višak slike */
}

/* Stil za slike unutar Slider-a */
.Slider__images img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Slika će zauzeti cijeli prostor unutar div-a */
  height: auto; /* Visina automatski ovisno o širini */
  object-fit: contain; /* Održava proporcije slika, može se prilagoditi */
}

/* Stil za klizač */
.Slider__input {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 300px;
  z-index: 10;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* WebKit klizač (Chrome, Safari) */
.Slider__input::-webkit-slider-thumb {
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Firefox klizač */
.Slider__input::-moz-range-thumb {
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Stil za tekst ispod slike */
.Advertising__card-content {
  background-color: aliceblue;
  margin-top: 20px;
  text-align: center;
}

/* Mobilni uređaji */
@media (max-width: 768px) {
  .Slider__input {
    width: 90%;
  }
  
  .Advertising__card-content {
    margin-top: 15px;
  }

  /* Možete povećati visinu slidera na mobilnim uređajima */
  .Slider {
    height: 250px; /* Prilagodite visinu za mobilne uređaje */
  }
}

/* Stil za vizuale u ponudama */
.Offers__header {
  display: flex;
  gap: 1rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  align-items: center;
}


