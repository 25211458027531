.Partneri {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Partneri__cards {
  display: grid; /* Koristimo CSS Grid */
  grid-template-columns: repeat(3, 1fr); /* Tri kartice po liniji */
  gap: 20px; /* Razmak između kartica */
  justify-content: center;
}

.Partneri__card {
  width: 100%; /* Kartice zauzimaju punu širinu svojih grid ćelija */
  max-width: 350px; /* Ograničavamo maksimalnu širinu kartica */
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.Partneri__card-image {
  width: 100%;
  height: 200px; /* Postavite visinu slike po želji */
  overflow: hidden;
}

.Partneri__card-image img {
  width: 100%; /* Slika pokriva cijeli prostor kartice */
  height: 100%; /* Slika pokriva cijeli prostor kartice */
  object-fit: contain; /* Slika se prilagođava unutar prostora bez obrezivanja */
}

.naslovPartnera {
  color: white; /* Postavljanje boje teksta na bijelu */
  text-align: center;
  margin-top: 1rem;
}

.Partneri__card-content {
  padding: 1rem;
  text-align: center;
}

.Partneri__card-content h3 {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
}

.Partneri__card-content p {
  margin: 1rem 0;
}

.Partneri__card-content a {
  display: inline-block;
  background-color: #005aaa;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
}

.Partneri__card-content a:hover {
  background-color: #004e8c;
}

@media screen and (max-width: 768px) {
  .Partneri__cards {
    grid-template-columns: repeat(3, 1fr); /* Tri kartice po liniji na manjim ekranima također */
  }

  .Partneri__card {
    max-width: 100%; /* Kartice na manjim ekranima zauzimaju punu širinu */
  }

  .Partneri__card-image img {
    object-fit: contain; /* Održava proporcionalnost slike bez obrezivanja */
  }
}

.Offers__header__Partneri {
  display: flex;
  gap: 1rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  align-items: center;
}
