.join__app {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}

.join__left{
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.join__left>hr{
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;

}

.join__left>div {
  display: flex;
  gap: 1rem;
  align-items: baseline;
}

.join__right {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.email_container {
  display: flex;
  gap: 2rem;
  background-color: gray;;
  padding: 1rem 2rem;
}

.email_container>input {
background-color: transparent;
border: none;
outline: none;
color: var(lightgray);
}

::placeholder {
  color: var(--lightgray);
}

.btn__join {
  background-color: var(--orange);
  color: white;
  width: 6rem;
}

@media screen and (max-width: 767px) {
  .join__app {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .join__left {
    font-size: x-large;
    margin-bottom: 1rem; /* Opcionalno, dodaje razmak između tekstova */
  }

  .join__left > div > span:nth-child(2) {
    color: var(--orange);
  }

  .join__right {
    padding: 2rem;
  }
}
