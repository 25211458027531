.footer__app{
  position: relative;
}

.blur-right{
  bottom: 0rem;
  right: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: red;
}
.blur-left{
  bottom: 0rem;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: red;
}


.footer__app>hr{
border: 1px solid var(--lightgray);
}

.footer__container{
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20rem;
  gap: 4rem;
}

.social__media{
  display: flex;
  gap: 4rem;
}

.social__media>img{
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.footer__logo>img{
  width: 10rem;
}