.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 4rem; /* Manja veličina loga */
  height: 4rem; /* Visina jednaka širini za okrugli oblik */
  border-radius: 50%; /* Okrugli oblik */
  object-fit: cover; /* Skalira sliku da ispunjava okrugli okvir */
}

.header__links {
  display: flex;
  list-style: none;
  gap: 1.2rem;
  position: relative;
}

.links > a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
}

.links > a:hover {
  color: var(--orange);
}

/* Dropdown za podlinkove */
.links {
  position: relative;
}

.links:hover .dropdown {
  display: block; /* Prikaži podmeni na hover */
}

.dropdown {
  display: none; /* Sakrij podmeni */
  position: absolute;
  top: 100%; /* Pozicioniraj ispod glavnog linka */
  left: 0;
  background-color: var(--appColor);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dropdown > li {
  margin: 0.5rem 0; /* Razmak između podlinkova */
}

.dropdown > a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  position: relative;
}

/* Mali krug ispred svakog podlinka */
.dropdown > a::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: var(--orange); /* Ovdje možete promijeniti boju kruga */
  border-radius: 50%; /* Krug */
  position: absolute;
  left: -1rem; /* Razmak između kruga i teksta */
  top: 50%;
  transform: translateY(-50%);
}

.dropdown > a:hover {
  color: var(--orange);
  background-color: rgba(255, 255, 255, 0.1);
}

/* Mobile style adjustments */
@media screen and (max-width: 768px) {
  .header__links {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--appColor);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 1.5rem;
    z-index: 22;
    transition: transform 0.3s ease-in-out;
  }

  .header__links a {
    font-size: 1.2rem;
  }

  .header__links ul {
    list-style: none;
    padding: 0;
  }

  .links {
    margin-bottom: 1rem;
  }

  .dropdown {
    position: static; /* Ukloni apsolutno pozicioniranje za mobilni prikaz */
    background-color: transparent; /* Ukloni pozadinu za dropdown */
    box-shadow: none;
    padding: 0;
  }

  .dropdown > li {
    margin: 0; /* Nema razmaka između podlinkova na mobilnom */
  }

  .dropdown > a {
    padding: 0.5rem 0; /* Manje paddinga na mobilnom */
  }

  .links > a {
    font-size: 1.2rem;
    text-align: center; /* Poravnanje linkova u centru na mobilnom */
  }

  .links:hover .dropdown {
    display: none; /* Sakrij podlinkove na hover za mobilni */
  }

  .links.active .dropdown {
    display: block; /* Prikaži podlinkove kad je aktivan meni */
  }
}
