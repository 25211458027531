.app__navigation {
  display: flex;
  justify-content: flex-end;   /* Pomicanje dot točaka na desnu stranu */
  align-items: center;
  flex-direction: column;      /* Poredaj dot točke vertikalno */
  padding: 0.3rem;
  position: fixed;             /* Drži dot točke uvijek na ekranu */
  top: 50%;                    /* Postavljanje na sredinu ekrana po visini */
  right: 0.3rem;                 /* Udaljenost od desnog ruba ekrana */
  z-index: 1000;               /* Osiguraj da je iznad drugih elemenata */
  transform: translateY(-50%);  /* Centriranje dot točaka vertikalno */
}

.app__navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: orange;    /* Narančasta boja za dot točke */
  margin: 0.5rem;
  transition: background-color 0.2s ease-in-out;
  position: relative;
}

.app__navigation-dot:hover {
  background-color: darkorange; /* Narančasta boja pri hoveru */
}

/* Tooltip koji se prikazuje samo na hover */
.tooltip {
  visibility: hidden;
  position: absolute;
  top: 20px;  /* Prilagodite razmak od dot točke */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.app__navigation-dot:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 765px) {
  .app__navigation {
    display: none;  /* Skriva navigation dots na manjim ekranima */
  }
}
