/* Glavni wrap container */
/* Zauzima cijelu vidljivu stranicu */
.app__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1200px;  /* Dodaj maksimalnu širinu za cijeli container */
  margin: 0 auto;     /* Centriranje cijelog sadržaja */
}

/* Wrapper za sadržaj */
.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

/* Ovdje dodajemo maksimalnu širinu za sadržaj */
.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;  /* Dodaj maksimalnu širinu */
  margin: 0 auto;     /* Centriranje sadržaja */
  width: 100%;        /* Osiguraj da uzima punu širinu do maksimalne */
}

.p-text {
  font-size: 0.7rem;
  text-align: left;
  color: black;
  line-height: 0.5;
}

.copyright {
  width: 100%;
  padding: 2rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: black;
}

p {
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  /* CSS styles for screens with a width of 768px or less */
  .copyright {
    margin-right: 1.5rem;
  }
  .p-text {
    font-size: 0.5rem;
  }
}

/* Dodaj social ikone */
.app__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding: 0.1rem;
}

@media screen and (max-width: 768px) {
  /* CSS styles for screens with a width of 768px or less */
  .app__social {
    display: none;
  }
}

/* Stilizacija za social ikone */
.aaa {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: lightgray;
  margin: 0.25rem 0;
  border: 1px solid var(--lightGray-color);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;
}

svg {
  width: 15px;
  height: 15px;
  color: var(--gray-color);
}

.aaa:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

svg {
  color: var(--white-color);
}

/* COPYRIGHT */
.copyright {
  padding: 2rem;
}

.p-text {
  font-size: 0.7rem;
  text-align: left;
  color: gray;
  line-height: 0.5rem;
}
