#itsolutions {
  text-align: center;
  padding: 10px;
}

.it__header__it {
  display: flex;
  gap: 0.5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  align-items: center;
}

.page-status {
  font-size: 1.2rem;
  color: gray;
  margin-bottom: 20px;
}

.services {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.services h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.services-list {
  list-style: none;
  padding: 0;
  font-size: 1rem;
}

.services-list li {
  margin: 5px 0;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.highlight {
  font-weight: bold;
  color: #007bff;
  margin: 10px 0;
}

.contact {
  background: #e3f2fd;
  padding: 20px;
  border-radius: 8px;
}

.contact h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact a {
  color: #007bff;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

.centered-image {
  width: 70%;
  height: auto;
  margin: 20px auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive stilovi */
@media (max-width: 768px) {
  .centered-image {
    width: 100%;
  }
  
  .Offers__header__it {
    font-size: 2rem;
  }
  
  .services h2 {
    font-size: 1.5rem;
  }
}

/* Galerija */
.gallery {
  padding: 20px;
  margin: 20px auto;
}

.gallery__title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.gallery__images {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.gallery__image {
  width: 150px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
