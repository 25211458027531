.Reasons {
  padding: 0.2rem;
  display: flex;
  gap: 2rem;
}

{/*right side-------------*/}
.reasons__right{
  flex:  1 1;
  text-transform: uppercase;
  gap:1rem;
  display: flex;
  flex-direction: column;
}
.reasons__right>img {
  object-fit: cover;
}

.reasons__right>span{
  font-weight: bold;
  color: var(--orange);
}
.reasons__right>div{
  font-size: 3rem;
  font-weight: bold;
  color: var(--orange);
}

.details{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details>div{
  display: flex;
  font-size: 1rem;
  gap: 1rem;
  color: white;
}
.details>div>img{
width: 2rem;
height: 2rem;
}

.ourPartners {
  color: var(--gray);
  font-size: 1rem;
}
.partners{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.partners>img{
width: 4rem;
}


{/*left side-------------*/}
.reasons__left{
  flex:  1 1;
  display: grid;
  grid-template-columns: (3,1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.reasons__left>:nth-child(1) {
  width: 12rem;
  grid-row: 1/3;
  height: 28rem;
}
.reasons__left>:nth-child(2) {
  width: auto;
  height: 16rem;
  grid-column: 2/4;
}
.reasons__left>:nth-child(3) {
  width: 14rem;
  grid-column: 2/3;
  grid-row: 2;
}
.reasons__left>:nth-child(4) {
  width: 10rem;
  grid-row: 2;
  grid-column: 3/4;
  height: 11.2rem;;
}

@media screen and (max-width: 767px) {
  .Reasons {
    flex-direction: column;
  }

  .reasons__right{
    padding: 1rem;
  }

  .reasons__left {
    grid-auto-rows: auto;
    overflow: hidden;
    gap: 0.3rem;
    padding: 1rem;
    justify-content: center;
  }

  .reasons__left>:nth-child(1) {
    width: 7rem;
    height: 16rem;
  }
  .reasons__left>:nth-child(2) {
    width: 15rem;
    height: 10rem;
  }
  .reasons__left>:nth-child(3) {
    width: 7rem;
  }
  .reasons__left>:nth-child(4) {
    width: 7rem;
    height: 6rem;
  }




}