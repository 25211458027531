.product-section {
    padding: 20px;
    text-align: center;
  }
  
  .product-section__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Tri u nizu na velikim ekranima */
    gap: 15px;
  }
  
  .product-item {
    border: 1px solid #ccc;
    padding: 15px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .product-item__image {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  
  .product-item__name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .product-item__price {
    font-size: 1rem;
    color: green;
    margin-bottom: 10px;
  }
  
  .product-item__description {
    font-size: 0.9rem;
    color: #555;
  }
  
  /* Za manje ekrane, dva u nizu */
  @media (max-width: 768px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr); /* Dva u nizu na manjim ekranima */
    }
    .Offers__header {
    font-size: 1.5rem;
    flex-direction: row;
    }
  }
  
  /* Za vrlo male ekrane, također dva u nizu */
  @media (max-width: 480px) {
    .product-grid {
      grid-template-columns: repeat(2, 1fr); /* Dva u nizu na najmanjim ekranima */
    }
    .Offers__header {
      font-size: 1.5rem;
      flex-direction: row;
      }
  }
  