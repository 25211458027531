.app__offers {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.Offers {
  display: flex; 
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: #656565; /* Vraćena stara pozadina */
}

.Offers__header {
  display: flex;
  gap: 1rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  align-items: center;
  margin-bottom: 2rem;
}

.offers__programs {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap; /* Omogućava prelazak u novi red ako nema dovoljno prostora */
  gap: 0.7rem; /* Duplo veći razmak između elemenata vodoravno */
  justify-content: center; /* Poravnava elemente na sredini */
}

.offers {
  display: flex;
  flex-direction: column;
  background-color: gray;
  padding: 1rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
  margin-bottom: 0.2rem; /* Duplo veći razmak okomito između elemenata */
}

.offers:hover {
  background: #f48915;
  transform: scale(1.05); /* Efekt zumiranja pri prelasku miša */
  cursor: pointer;
}

.offers>:nth-child(1) {
  width: 20rem; /* Veća ikona za bolju vidljivost */
  height: 1rem;
  fill: white;
  
}

.offers>:nth-child(2) {
  font-size: 1.2rem; /* Povećana veličina naslova */
  font-weight: bold;
}

.offers>:nth-child(3) {
  font-size: 1rem; /* Povećana veličina za bolju čitljivost */
  line-height: 1.6;
}

.join_now {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.join_now > img {
  width: 1.2rem; /* Povećana veličina ikone */
}

/* Responsivni dizajn */
@media screen and (max-width: 767px) {
  .Offers__header {
    flex-direction: column;
    gap: 1rem;
    font-size: 1.8rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    color: white;
  } 

  .offers__programs {
    flex-direction: column; /* Jedan ispod drugoga na malim ekranima */
  }

  .offers {
    width: 100%; /* Širina 100% za male ekrane */
    padding: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .offers {
    width: calc(70% - 1rem); /* Na većim ekranima, tri elementa u redu */
    flex-direction: column;
  }
}

/* Stil za uvodni tekst sa bijelim okvirom */
.Offers_Poruka {
  background: white; /* Bijela pozadina */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center; /* Centriranje sadržaja horizontalno */
  align-items: center; /* Centriranje sadržaja vertikalno */
}
