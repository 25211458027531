/* Stil za uvodni tekst sa bijelim okvirom */
.Poruka {
  display: flex;
  background: white; /* Bijela pozadina */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
}

/* Stil za vizuale u ponudama */
.Oglasavanje__header {
  display: flex;
  gap: 1rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  align-items: center;
}

/* Opći stilovi */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #656565;
  color: #333;
}

.oglasavanje {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.advertising-header2 {
  text-align: center;
  margin-bottom: 2rem;
}

.advertising-header2 h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #d9d9d9;
}

.advertising-header p {
  font-size: 1rem;
  color: #d9d9d9;
}

/* Wrapper za kartice */
.advertising-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

/* Kartica */
.advertising-card {
  background: #fff;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.advertising-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Slika unutar kartice */
.advertising-card-image {
  height: 200px;
  background-color: #eee;
  overflow: hidden;
}

.advertising-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Sadržaj kartice */
.advertising-card-content {
  padding: 1rem;
}

.advertising-card-content h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #005aaa;
}

.advertising-card-content p {
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  color: #555;
}

.advertising-card-content strong {
  color: #333;
}

/* Lokacije */
.advertising-card-locations {
  margin-top: 1rem;
  padding: 0.5rem;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Responsivnost */
@media (min-width: 768px) {
  .advertising-card {
    width: calc(50% - 2rem); /* Dvije kartice u jednom redu */
  }  
}

@media (max-width: 767px) {
  .advertising-card {
    width: 100%; /* Jedna kartica u jednom redu */
  }
  .Oglasavanje__header {
    font-size: 1.7rem;
    }
}

.Offers__header__advertising {
  display: flex;
  gap: 1rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  align-items: center;
}

.it__header {
  display: flex;
  gap: 1rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  align-items: center;
  margin-bottom: 2rem;
}


/* Stil za filtriranje */
.filter-options {
  text-align: center;
  margin: 20px 0;
}

.filter-checkbox label {
  font-size: 1rem;
  color: red; /* Blago siva boja za tekst */
  cursor: pointer;
}

.filter-checkbox input[type="checkbox"] {
  background-color: red;
  margin-right: 10px;
  transform: scale(1.2); /* Lagano povećanje veličine checkboxa */
  cursor: pointer;
}
.text {
  color: white;
}



