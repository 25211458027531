.iskopi-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem;
  background-color: #656565;
}

.iskopi-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.iskopi-header h2 {
  font-size: 2.5rem;
  color: #d9d9d9;
}

.iskopi-header p {
  font-size: 1.2rem;
  color: #d9d9d9;
  margin-top: 1rem;
}

.iskopi-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-top: 2rem;
}

.iskopi-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.iskopi-image:hover {
  transform: scale(1.05);
}

.iskopi-info {
  text-align: center;
  max-width: 800px;
  margin: 2rem auto;
}

.iskopi-info h3 {
  font-size: 2rem;
  color: #d9d9d9;
}

.iskopi-info ul {
  list-style: none;
  padding: 0;
}

.iskopi-info li {
  font-size: 1.1rem;
  color: #d9d9d9;
  margin: 0.5rem 0;
}

@media screen and (max-width: 768px) {
  .iskopi-gallery {
    grid-template-columns: 1fr;
  }

  .iskopi-header h2 {
    font-size: 2rem;
  }

  .iskopi-header p {
    font-size: 1rem;
  }

  .iskopi-info h3 {
    font-size: 1.5rem;
  }

  .iskopi-info li {
    font-size: 1rem;
  }
}
