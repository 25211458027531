
.app__hero {
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: red;
}

.Hero {
  display: flex; 
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
 }

 .proba{
  display: flex;
  width: 100%;
 }
 @media screen and (max-width: 768px) {
  /* .CSS styles for screens with a width of 768px or less */
  .proba {
    flex-direction: column;
    justify-content: center;
  }
}
  



.hero {
  display: flex;
  justify-content: space-between;
}


.hero-blur{
  width: 22rem;
  height: 30rem;
  left: 0;
}

.hero__left {
  padding: 2rem;
  padding-top: 1.2rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  }


.thebest {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 1.25rem 0.8rem;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.thebest>span{
z-index: 2;
letter-spacing: 1px;

}
.thebest>div{
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  border-radius: 3rem;
  left: 8px;
  z-index: 1;
}

.hero__text{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Added text shadow for better readability */
}

.hero__text>div:nth-of-type(3){
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

/*BROJAĆI*/
.brojaci{
  display: flex;
  gap:2.5rem;
}
.brojaci>div{
  display: flex;
  flex-direction: column;
}
.brojaci>div>span:nth-of-type(1){

  color: white;
  font-size: 2rem;
}
.brojaci>div>span:nth-of-type(2){
  color: var(--gray);
  text-transform: uppercase;
}

.hero-buttons{
  display: flex;
  gap: 1rem;
  font-weight: normal;
}
.hero-buttons>:nth-child(1){
  color: white;
  background-color: var(--orange);
  width: 8rem;
}
.hero-buttons>:nth-child(2){
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid;
  border-color: var(--orange);
}

{/*right side------------------- */}

.hero__right {
  flex:1;
  position: relative;
  background-color: var(--orange);
}
.hero__right>.btn{
  position: absolute;
  right:3rem;
  top: 2rem;
  color: black;
}

.heart-rate {
  display: flex;
  flex-direction: column;
  gap:1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: flex-start;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 7rem;
}

.heart-rate>:nth-child(2){
  color: var(--gray);
}
.heart-rate>:nth-child(3){
  color: white;
  font-size: 1.5rem;
}
.heart-rate>img{
  width: 2rem;
}

.hero_image {
  position: absolute;
  top: 12rem;
  right: 3rem;
  width: 28rem;
}

.hero_image_back {
  position: absolute;
  top:4rem;
  right: 18rem;
  width: 15rem;
  z-index: -1;
}

.red{
  position: absolute;
  top:15rem;
  right: 8rem;
  font-size: 7rem;
  
}



.calories{
  display: flex;
  gap:2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top:37rem;
  right: 20rem;
}

@media screen and (max-width: 768px) {
  /* CSS styles for screens with a width of 768px or less */
  .calories {
    gap: 1rem;
    padding: 0.8rem;
  }
}


.calories>div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calories>div>:nth-child(1){
  color: var(--gray);
}
.calories>div>:nth-child(2){
  color: white;
  font-size: 1.5rem;
}



@media screen and (max-width:768px) {
  .hero {
    flex-direction: column;
  }
  .hero-blur{
    width: 14rem;
  }
  .thebest{
  width: 90vw;

    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
    justify-content: space-around;
  }
  .hero__text{
    font-size:xx-large ;
    justify-content: center;
    align-items: center;  
  }
  .hero__text>div:nth-of-type(3){
  font-size: small;
  text-align: center;
  }
  .brojaci{
    justify-content: center;
  }
  .brojaci>div{
    align-items: center;
  }
  .brojaci>div>span:nth-of-type(1){
    color: white;
    font-size: large;
  }
  .brojaci>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: uppercase;
    font-size: small; 
  }

  .hero-buttons{
    justify-content: center;
  }

  .hero__right{
    position: relative;
    width: 100vw;
    background: none;
    justify-content:center ;
    align-items: center;
  }

  .hero__right>img{
    position: relative;
    justify-content: center;
    align-items: center;

  }
  .hero_image{
    position: relative;
    top: 2rem;
    right: -1rem;
    width: 90vw;
   }

   .red{
    position: absolute;
    top: 2rem;
    font-size: xx-large;
    justify-self: center;
    }

    .calories {
      position: relative;
      top: 5rem;
      left: 3rem;
    }
    .calories>img {
      width: 2rem;
    }

    .hero_image_back{
    display: none;
    }

}