/* Global Variables */
:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGray: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
}

/* Ensure consistent box-sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--lightgray);
  background-color: var(--appColor);
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.App {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  position: relative;
  min-height: 100vh;
}

/* Hide scrollbars for a cleaner look */
::-webkit-scrollbar {
  display: none;
}

.stroke-text {
  color: transparent;
  -webkit-text-stroke: 1px white;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .stroke-text {
    -webkit-text-stroke: 0;
    color: white; /* Default color on small screens */
  }
}

.btn {
  background-color: white;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
  cursor: pointer;
  background-color: var(--orange);
  transform: scale(1.05);
}

/* Navigation styles */
.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  position: fixed;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 1000;
}

.app__navigation-dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--gray);
  margin: 0.5rem 0;
  transition: background-color 0.2s ease, transform 0.2s ease;
  cursor: pointer;
}

.app__navigation-dot:hover,
.app__navigation-dot:focus {
  background-color: var(--orange);
  transform: scale(1.2);
}

.app__navigation-dot:focus {
  outline: 2px solid var(--orange);
}

a {
  color: var(--orange);
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover,
a:focus {
  color: white;
  text-decoration: underline;
}

/* Blur effects */
.blur {
  position: absolute;
  border-radius: 50%;
  background: rgba(253, 120, 43, 0.69);
  filter: blur(150px);
  z-index: -1;
  will-change: transform;
  max-width: 100vw; /* Ensure it doesn't overflow */
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .app__navigation {
    flex-direction: row;
    bottom: 1rem;
    left: 50%;
    top: auto;
    transform: translateX(-50%);
  }
  .app__navigation-dot {
    margin: 0 0.5rem;
  }
}

/* Accessibility helper classes */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* For mobile responsiveness and centering the scroll-up button */
.scroll-up-btn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  background-color: red;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.scroll-up-btn:hover {
  transform: scale(1.1);
  background-color: #f57922; /* Darker shade on hover */
}

/* Ensure no element exceeds viewport width */
.App,
.app__navigation,
.scroll-up-btn {
  max-width: 100%;
  overflow-x: hidden;
}

/* For larger screens */
@media screen and (min-width: 768px) {
  .scroll-up-btn {
    bottom: 20px;
    right: 20px;
  }
}

.my-custom-scroll-btn {
  background-color: #ff5722; /* Narančasta boja */
  right: 20px !important;/* Pomaknite desno */
  bottom: 50px; /* Pomaknite dolje */
  position: fixed;
  z-index: 1000; /* Osigurajte da je iznad svega */
  cursor: pointer;
  border-radius: 50%; /* Zaokruženi gumb */
  width: 50px; /* Širina gumba */
  height: 50px; /* Visina gumba */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Sjena */
  border: none;
}

.my-custom-scroll-btn:active {
  transform: scale(0.9); /* Lagano smanjivanje na klik */
}

.my-custom-scroll-btn svg {
  fill: black; /* Boja strelice */
  width: 24px;
  height: 24px;
  margin: auto;
}

