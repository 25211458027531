.Advertising {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Advertising__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Advertising__card {
  width: 100%;
  max-width: 400px;
  margin: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.Advertising__card-image {
  height: 300px;
  overflow: hidden;
}

.Advertising__card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Advertising__card-content {
  padding: 1rem;
}

.Advertising__card-content h3 {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
}

.Advertising__card-content p {
  margin: 1rem 0;
}
.Advertising__card-content>p>span {
  font-size: 1.3rem;
  margin: 1rem 0;
  padding: 0.5rem;
  font-weight: bold;
}

.Advertising__card-content a {
  display: inline-block;
  background-color: #005aaa;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
}

.Advertising__card-content a:hover {
  background-color: #004e8c;
}
