.testimonials__app {
  display: flex;
  gap: 3rem;
  padding: 0 2rem;
}

.testimonials__left {
  display: flex;
  flex: 1 1;
  gap: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
}
.testimonials__left>:nth-child(1) {
color: var(--orange);
font-weight: bold;
}

.testimonials__left>:nth-child(2),
.testimonials__left>:nth-child(3) {
font-size: 3rem;
font-weight: bold;
}


.testimonials__left>:nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}

.testimonials__right {
  flex:  1 1;
  position: relative;
}

.testimonials__right>img{
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;;
}

.testimonials__right>:nth-child(1){
  position: absolute;
  width: 17rem;
  height: 20rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 9rem;
  top: 0.9rem;
}

.testimonials__right>:nth-child(2){
position: absolute;
width: 17rem;
height: 19rem;
top:4rem;
background: var(--planCard);
right: 7rem;
}

.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
}

.arrows>img {
  width: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  
  .testimonials__app {
  flex-direction: column;
  align-items: center;
  }
  
  .testimonials__left {
  gap: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  }

  .testimonials__header{
    font-size: large;
  }

  
  .testimonials__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  }
  
  .testimonials__right>img {
    position: relative;
    align-self: center;
    width: 12rem;
    height: 15rem;
    right: 0;
    top: 0;
  }
  
  .testimonials__right>:nth-child(1) {
  width: 12rem;
  height: 14rem;
  right: 1rem;
  top: -1rem;
  }
  
  .testimonials__right>:nth-child(2) {
  width: 12rem;
  height: 14rem;
  top: 2rem;
  right: -1rem;
  }
  
.arrows {
  position: relative;
  bottom: 0;
  top:0;
  align-self: center;
}

.arrows>img {
  width: 1.5rem;
  cursor: pointer;

}
}
